<template>
    <div class="main">
        <ProfileForm></ProfileForm>
    </div>
</template>
<script>
import ProfileForm from  "./components/form.vue";
export default {
    name: "TeacherProfile",
    components: {
        ProfileForm
    }
}
</script>