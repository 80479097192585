<template>
  <div class="row">
    <div class="col-12">
      <div class="profile">
        <div class="cimg">
          <img
            :src="
              teacher && teacher.profile_image
              ? teacher.profile_image : ''
            "
          />
        </div>
        <h3 class="name">{{ 
              teacher && teacher.name 
              ? teacher.name 
              : "no nickname" 
              }}</h3>
      </div>
    </div>
    <div class="col-12">
      <div class="rate">
          <div class="d-flex justify-content-between ">
              <div class="star">
                  <star-rating
                      :rating="Number(teacher && teacher.avg_rating ? teacher.avg_rating : 0)"
                      :star-size="22"
                      :show-rating="false"
                      :read-only="true"
                    />
                  {{ _totalRate(teacher && teacher.avg_rating ? teacher.avg_rating : 0) }}
                  ({{ teacher && teacher.rate_qty ? teacher.rate_qty : 0 }})
              </div>
              <div :class="`btn-fav ${teacher.is_favorite ? 'favorited' : ''}`"  
                  role="button" 
                  @click="handleClick">
                  <i class="fa fa-heart-o" aria-hidden="true"></i>
                  {{ $t("lesson.detail.favorite") }}
                  <template v-if="teacher && teacher.favorite_qty">
                    {{ teacher.favorite_qty }}
                    {{$t('teacher.profile.button.person')}}
                  </template>
              </div>
          </div>
      </div>
    </div>
    <div class="col-12">
      <div class="text-intro text-justify text-pre-line"
          v-if="teacher && teacher.introduction">
          {{ teacher.introduction }}
      </div>
    </div>
    <RegisterModal
      :isVisible="this.isVisible"
      @cancel="actionCancel"
    />
    <MetaTags 
        :title="`${teacher.name}｜ResMom相談online`"
        :description="teacher.introduction"
        :ogImage="teacher.profile_image"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isVisible: false,
      addFavoriting: false
    }
  },
  async created() {
      if (!this.member) {
          await this.getMMSMember();
      }
      let teacherId = this.$route.params.teacherId;
      const memberId = this.member && this.member.id ? this.member.id : 0;
      await this.fetchTeacherProfile({ id: teacherId, member_id: memberId });
  },
  computed: {
    ...mapGetters({
      member: "getMember",
      teacher: "teacherProfile/getTeacherProfile",
    }),
  },
  methods: {
    ...mapActions({
      fetchTeacherProfile: "teacherProfile/fetchTeacherProfile",
      actionUpdateFavorite: "teacherProfile/updateFavorite",
      getMMSMember: "getMMSMember",
    }),
    async handleClick() {
      if (this.member) {
        if(this.addFavoriting)return;
        this.addFavoriting = true;
        const memberId = this.member && this.member.id ? this.member.id : 0;
        let response = await this.actionUpdateFavorite({
                                id: this.teacher.member_id,
                                member_id: memberId,
                              });
        if (response.data) {
          let teacherId = this.$route.params.teacherId;
          const memberId = this.member && this.member.id ? this.member.id : 0;
          this.fetchTeacherProfile({ id: teacherId, member_id: memberId });
        }
        this.addFavoriting = false;
      } else {
        this.isVisible = true;
      }
    },
    actionCancel() {
      this.isVisible = false;
    },
    _totalRate(rate) {
        rate = rate.toString().slice(0, 3);
        return rate;
    },
  },
};
</script>

<style scoped>
.bi-heart-fill {
  color: red;
  cursor: pointer;
}
</style>